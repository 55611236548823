import { writable, get, derived } from "svelte/store";
import fetcher from "../utils/fetcher";
import { user } from "./context";
import { PAGE_SIZE } from "../../modules/config";
import { TIMELINE_CREATED, DEFAULT_EVENT } from "../utils/consts";

export const timelines = writable([]);
export const currentTimeline = writable({});
export const currentTimelineFullyLoaded = writable(false);
let currentTimelinePage = 0;

export const currentTimelineEvents = derived(
  [currentTimeline, timelines],
  ([{ isMain, events = [] }, $timelines]) => {
    let result = events.map((i) => ({ ...i, type: DEFAULT_EVENT }));
    if (isMain) {
      result = result.concat(
        $timelines.filter((i) => !i.isMain).map(timelineCreationEvent)
      );
    }
    result.sort(sortByCreationDate);
    return result;
  }
);

export const timelineCreationEvent = ({
  _id,
  createdAt,
  name,
  color,
  url,
}) => ({
  _id,
  type: TIMELINE_CREATED,
  createdAt,
  name,
  color,
  url,
});

const sortByCreationDate = (a, b) =>
  a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;

export const loadTimelineData = async (username, timeline) => {
  if (!username && !timeline) {
    await loadMainTimeline();
  } else if (!timeline) {
    await loadMainTimeline(username);
  } else {
    await loadTimelineByUsernameAndUrl(username, timeline);
  }
};

export const loadTimelinesByUser = (username) =>
  fetcher(
    `/api/private/timelines?username=${username || get(user).username}`
  ).then((data) => timelines.set(data));

const loadTimelineByUsernameAndUrl = (username, url) =>
  fetcher(`/api/private/timelines?username=${username}&url=${url}`).then(
    setCurrentTimeline
  );

export const loadMainTimeline = (username) =>
  fetcher(
    `/api/private/timelines?username=${
      username || get(user).username
    }&isMain=true`
  ).then(setCurrentTimeline);

const setCurrentTimeline = (data) => {
  const timelineData = Array.isArray(data) ? data[0] : data;
  currentTimeline.set(timelineData);
  currentTimelinePage = 0;
  currentTimelineFullyLoaded.set(timelineData.events.length < PAGE_SIZE);
};

export const loadNextPage = () =>
  fetcher(
    `/api/private/events?timeline=${
      get(currentTimeline)._id
    }&page=${++currentTimelinePage}`
  ).then((data) => {
    currentTimeline.update((timeline) => {
      timeline.events = [...timeline.events, ...data];
      return timeline;
    });
    if (data.length < PAGE_SIZE) {
      currentTimelineFullyLoaded.set(true);
    }
  });
