<script>
  import { navigate } from "svelte-routing";
  import Poppover from "./common/Poppover.svelte";
  import Icon from "./common/Icon.svelte";
  import { logout } from "../utils/authentication";

  const navigateToSettings = (closePoppover) => () => {
    setTimeout(closePoppover);
    navigate("/profile");
  };
</script>

<div class="userMenu">
  <Poppover name="userMenu" let:closePoppover>
    <div slot="trigger" class="trigger">
      <Icon name="settings" />
    </div>
    <div slot="modal">
      <ul>
        <li on:click="{logout}">
          <span>Logout</span>
          <Icon name="log-out" />
        </li>
        <li on:click="{navigateToSettings(closePoppover)}">
          <span>Profile</span>
          <Icon name="user" />
        </li>
      </ul>
    </div>
  </Poppover>
</div>

<style>
  .trigger {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: var(--elevation-0);
    color: var(--grey-900);
  }
  ul {
    margin: 0;
    padding: 0;
    min-width: 120px;
  }
  li {
    padding: var(--spacing-small);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  li:hover {
    background-color: var(--grey-100);
  }
</style>
