<script>
  import { createEventDispatcher } from "svelte";
  import { navigateToTimeline } from "../utils/routing";

  import { fly, slide } from "svelte/transition";
  import { isOwnTimeline, currentUser } from "../stores/context";
  import Icon from "./common/Icon.svelte";
  export let timeline;
  export let index;
  export let searchResult = false;

  const dispatch = createEventDispatcher();

  const authorBase = timeline.author.username || $currentUser;
</script>

<li
  style="border-color:{timeline.color}"
  class="item"
  on:click="{() => navigateToTimeline(authorBase, timeline.url)}"
  in:fly="{{ delay: index * 100 + 100, x: 300 }}"
  out:slide|local
>
  <span class="label">{timeline.name}</span>
  {#if $isOwnTimeline && !searchResult}
    <ul class="timelineActions">
      {#if !timeline.isMain}
        <li on:click|stopPropagation="{() => dispatch('deletetimeline')}">
          <Icon name="trash" />
        </li>
      {/if}
      <li on:click|stopPropagation="{() => dispatch('edittimeline')}">
        <Icon name="settings" />
      </li>
    </ul>
  {:else}<sub>{authorBase}</sub>{/if}
</li>

<style>
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 25px;
    border-left: var(--timeline-width) solid var(--white);
    padding: var(--spacing-medium);
    transition: var(--default-transition);
  }
  .item .label {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }
  .item:hover {
    border-left: 10px solid var(--white);
  }

  .item:hover .timelineActions {
    display: flex;
    align-items: center;
  }
  .timelineActions {
    display: none;
    color: var(--color-links);
    margin: 0;
    padding: 0;
  }
  .timelineActions li {
    margin: 0 var(--spacing-small);
  }
  .item sub {
    color: var(--grey-600);
  }

  @media only screen and (max-width: 600px) {
    li .timelineActions {
      display: flex;
      align-items: center;
    }
  }
</style>
