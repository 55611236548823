import dayjs from "dayjs";

const DATE_FORMAT = "MMM D";
const DATE_FORMAT_YEAR = "MMM D YYYY";
const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const TIME_FORMAT = "HH:mm";

export const relativeDate = (date) => {
  const daysJsInstance = dayjs(date);
  if (daysJsInstance.isBefore(dayjs().subtract(1, "year"))) {
    return daysJsInstance.format(DATE_FORMAT_YEAR);
  }
  if (daysJsInstance.isBefore(dayjs().subtract(1, "day"))) {
    return daysJsInstance.format(DATE_FORMAT);
  }
  if (daysJsInstance.isAfter(dayjs().subtract(3, "minutes"))) {
    return "Now";
  }
  return daysJsInstance.format(TIME_FORMAT);
};

export const format = (date, format = DATE_TIME_FORMAT) =>
  dayjs(date).format(format);
