<script>
  export let size = 50;
  export let stroke = "#222";
</script>

<!-- Loader 3 -->
<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 {size}
  {size}"
  enable-background="new 0 0 0 0"
  xml:space="preserve"
  height="{size}"
  width="{size}"
>
  <circle
    fill="none"
    stroke="{stroke}"
    stroke-width="4"
    cx="{size / 2}"
    cy="{size / 2}"
    r="{size / 2 - 6}"
    style="opacity:0.5;"
  ></circle>
  <circle
    fill="{stroke}"
    stroke="#fff"
    stroke-width="3"
    cx="8"
    cy="{size / 2 + 4}"
    r="4"
  >
    <animateTransform
      attributeName="transform"
      dur="2s"
      type="rotate"
      from="0 {size / 2}
      {size / 2 - 2}"
      to="360 {size / 2}
      {size / 2 + 2}"
      repeatCount="indefinite"
    ></animateTransform>
  </circle>
</svg>
