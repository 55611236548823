import fetcher from "../utils/fetcher";
import { get } from "svelte/store";
import { user } from "./context";

export const saveUser = () => {
  fetcher("/api/private/profile", {
    method: "PATCH",
    body: JSON.stringify(get(user)),
  });
};

export const refreshUser = () =>
  new Promise((resolve, reject) => {
    fetcher(`/api/private/profile`)
      .then((response) => {
        user.update((u) => ({
          ...u,
          username: response.username,
          login: response.login,
          preferences: response.preferences || {},
        }));
        resolve(response);
      })
      .catch((e) => reject(e));
  });
