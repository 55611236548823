<script>
  import { fade } from "svelte/transition";
  import MainEditor from "../components/MainEditor.svelte";
  import Loading from "../components/common/Loading.svelte";
  import EventItem from "../components/EventItem.svelte";
  import TimelineTitle from "../components/TimelineTitle.svelte";
  import TimelineNotFound from "../components/TimelineNotFound.svelte";
  import { onMount } from "svelte";
  import logger from "../../modules/logger";
  import {
    loadTimelineData,
    currentTimeline,
    loadNextPage,
    currentTimelineFullyLoaded,
    currentTimelineEvents,
  } from "../stores/timelines";
  import { user, isOwnTimeline, currentUser } from "../stores/context";
  import { MAIN_TIMELINE_CREATED } from "../utils/consts";

  export let path = $user.username;

  let loading = true;
  let loadingNextPage = false;
  let scroll;
  let height;
  let timelineEl;
  let timeline;
  let username;

  onMount(async () => {
    const pathParts = path.split("/");
    username = pathParts.shift() || $user.username;
    timeline = pathParts.shift() || "";
    try {
      currentUser.set(username);
      await loadTimelineData(username, timeline);
    } catch (e) {
      logger.error(e);
    }
    loading = false;
  });

  const loadMore = () => {
    if (loadingNextPage || $currentTimelineFullyLoaded) return;

    loadingNextPage = true;
    loadNextPage().then(() => {
      loadingNextPage = false;
    });
  };

  $: if (timelineEl && scroll + height >= timelineEl.offsetHeight - 500) {
    loadMore();
  }
</script>

<svelte:window bind:scrollY="{scroll}" bind:innerHeight="{height}" />

{#if loading}
  <Loading />
{:else if $currentTimeline}
  <main
    in:fade
    style="border-color: {$currentTimeline.color}"
    bind:this="{timelineEl}"
    class:finite="{$currentTimelineFullyLoaded}"
  >
    <TimelineTitle />
    <div class="timeline">
      {#if $isOwnTimeline}
        <MainEditor timelineId="{$currentTimeline._id}" />
      {/if}

      {#each $currentTimelineEvents as event, index (event._id)}
        <EventItem event="{event}" index="{index}" />
      {/each}
    </div>
    {#if loadingNextPage}
      <Loading />
    {/if}
    {#if $currentTimelineFullyLoaded}
      <EventItem
        event="{{ createdAt: $currentTimeline.createdAt, type: MAIN_TIMELINE_CREATED }}"
      />
    {/if}
  </main>
{:else}
  <TimelineNotFound />
{/if}

<style>
  main {
    padding-top: var(--spacing-xx-large);
    padding-left: var(--spacing-medium);
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    border-left: var(--timeline-width) solid var(--color-brand);
    width: 100%;
    max-width: var(--timeline-max-width);
    margin-left: var(--time-label-width);
  }
  main:after {
    content: "";
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    box-shadow: 0 0 10px 10px var(--color-background);
    left: calc(0px - var(--timeline-width));
  }

  main.finite:after {
    box-shadow: none;
  }

  main.finite {
    margin-bottom: 100px;
  }

  :global(main.finite > .event:last-of-type) {
    margin-top: 50px;
    margin-bottom: -25px;
    align-items: baseline;
  }

  .timeline {
    min-height: 40vh;
  }

  @media only screen and (max-width: 600px) {
    main {
      margin-left: var(--spacing-large);
      max-width: calc(100% - var(--spacing-x-large));
    }
  }
</style>
