export const BRAND_COLOR = "#29B6F6";
export const PAGE_SIZE = 10;
export const EVENTS_LOADED_WITH_TIMELINE = PAGE_SIZE;
export const MAX_SIZE_IMAGE = 1200;
export const MEDIA_FOLDER = `https://storage.googleapis.com/BUCKET_NAME/`;
export const RESERVED_PATHS = [
  "settings",
  "profile",
  "index",
  "manifest",
  "robots",
];
