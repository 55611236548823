import fetcher from "../utils/fetcher";
import { MEDIA_FOLDER } from "../../modules/config";

export const getSignedUrl = (file) =>
  fetcher("/api/private/medias/", {
    method: "POST",
    body: JSON.stringify({ name: file.name, size: file.size, type: file.type }),
  });

export const upload = (file, signedUrl) =>
  fetcher(signedUrl, {
    method: "PUT",
    noContentType: true,
    ignoreResponse: true,
    body: file,
  });

export const deleteMedia = (filename) =>
  fetcher(`/api/private/medias/${filename}`, {
    method: "DELETE",
  });

export const absoluteUrl = (filename) => MEDIA_FOLDER + filename;
