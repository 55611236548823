const clearCookies = () => {
  document.cookie
    .split(";")
    .forEach(
      (cookie) =>
        (document.cookie =
          cookie + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC")
    );
};

const isConnected = () =>
  document.cookie.split("; ").some((row) => row === "authed=true");

export { clearCookies, isConnected };
