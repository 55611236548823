import logger from "../../modules/logger";
import { logout } from "./authentication";

/**
 * Fetch or post data to API endpoint
 *
 * @param {string} url The url
 * @param {string} onfig The config to pass to the underlying fetch implementation
 *
 * @returns {Promise}
 */
const fetcher = (url, config = {}) => {
    const startTime = Date.now();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      ...config,
    };
    if (config.noContentType) delete options.headers["Content-Type"];
    return fetch(url, options)
      .then(log(startTime))
      .then(checkStatus)
      .then((r) => config.ignoreResponse || r.json())
      .catch((err) => {
        err && logger.error(err);
        throw err;
      });
  },
  /**
   * Log fetcher's response status and latency
   * @param {Response} response fetcher's response
   *
   * @returns {Response}
   */
  log = (startTime) => (response) => {
    logger.info(
      `Called ${response.url} - ${response.status} - ${
        Date.now() - startTime
      } ms`
    );
    return response;
  },
  /**
   * Helper method to throw error on status not ok
   * @param {Response} response fetcher's response
   *
   * @returns {Response | Promise}
   */
  checkStatus = (response) => {
    if (response.ok) {
      return response;
    }
    if (response.status === 403) {
      logout();
      const errorResponse = new Error(response.statusText);
      errorResponse.response = response;
      return Promise.reject(errorResponse);
    } else if (response.status === 500) {
      // backend error handling
      response.json().then((error) => {
        logger.error(error.message);
      });
      return Promise.reject("Server error, cannot log in.");
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      return Promise.reject(error);
    }
  };

export default fetcher;
