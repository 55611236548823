<script>
  import fetcher from "../utils/fetcher";
  import Icon from "./common/Icon";
  import { onMount } from "svelte";

  let sessions = [];
  let promise;

  onMount(async () => {
    promise = fetcher("/api/private/auth/list").then(
      (data) => (sessions = data)
    );
  });

  const onKill = (token) => async () => {
    await fetcher("/api/private/logout", {
      method: "POST",
      body: JSON.stringify({ token }),
    });
    document.location.reload();
  };
</script>

<p>Opened sessions:</p>
{#await promise}
  <p>...loading opened sesssion</p>
{:then}
  <ul>
    {#each sessions as session}
      <li>
        {session.agent}
        <Icon
          name="log-out"
          attr="{{ width: 16, height: 16 }}"
          title="logout"
          on:click="{onKill(session.token)}"
          clickable
        />
      </li>
    {/each}
  </ul>
{:catch error}
  <p class="error">{error.message}</p>
{/await}

<style>
  * {
    font-size: var(--font-size-tiny);
    color: var(--grey-700);
  }
  ul {
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-medium);
  }
  .error {
    color: var(--color-error);
  }
</style>
