<script>
  import { fade } from "svelte/transition";
  import { onMount, onDestroy } from "svelte";

  onMount(() => {
    document.body.style.overflow = "hidden";
  });

  onDestroy(() => {
    document.body.style.overflow = "auto";
  });
</script>

<div class="overlay" transition:fade on:click></div>

<style>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--overlay);
    z-index: 99;
  }
</style>
