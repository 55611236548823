<script>
  import { onMount, tick } from "svelte";
  import { slide } from "svelte/transition";
  import Icon from "./common/Icon.svelte";
  import Toggle from "./common/Toggle.svelte";
  import Input from "./common/Input.svelte";
  import { userLogin } from "../stores/context";
  export let privacy = [];
  export let isPrivate = false;

  onMount(async () => {
    await tick(); // TODO remove this and async when https://github.com/sveltejs/svelte/issues/3470 is fixed
    await tick(); // TODO remove this and async when https://github.com/sveltejs/svelte/issues/3470 is fixed
    isPrivate = privacy.length > 0;
    !privacy.length && (privacy = [$userLogin]);
  });

  const addUser = (event) => {
    const valueToSave = event.target.value.trim();
    if (valueToSave && !privacy.includes(valueToSave)) {
      privacy = [valueToSave].concat(privacy);
      event.target.value = "";
    }
  };
</script>

<div class="toggleContainer">
  <Toggle bind:value="{isPrivate}">{isPrivate ? 'Private' : 'Public'}</Toggle>
  {#if isPrivate}
    <Icon name="lock" />
  {:else}
    <Icon name="unlock" />
  {/if}
</div>

{#if isPrivate}
  <div class="privacyList" transition:slide>
    <div class="hint">
      Add emails to privacy so users have access to the timeline and can find
      it.
    </div>
    <ul>
      {#each privacy as user, index (user)}
        <li transition:slide|local>
          <span class="label">{user}</span>
          {#if user !== $userLogin}
            <Icon
              name="x"
              clickable="{true}"
              on:click="{() => (privacy = privacy.filter((i) => user !== i))}"
            />
          {/if}
        </li>
      {/each}
    </ul>
    <Input on:change="{addUser}" placeholder="user@email.com" />
  </div>
{/if}

<style>
  .toggleContainer {
    display: flex;
    align-items: center;
    margin: var(--spacing-medium) 0;
  }
  ul {
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow: auto;
    font-size: var(--font-size-small);
    color: var(--grey-700);
  }
  li {
    display: flex;
    justify-content: space-between;
    margin: var(--spacing-small) 0;
  }
  li .label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .privacyList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-medium);
    max-height: 300px;
    overflow: auto;
  }
  .hint {
    text-align: center;
    background: var(--grey-100);
    padding: var(--spacing-medium) var(--spacing-small);
    border-radius: var(--spacing-medium);
  }
</style>
