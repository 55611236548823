<script>
  import { navigateToTimeline } from "../utils/routing";

  import { fly, slide } from "svelte/transition";
  export let user;
  export let index;

  const switchTimeline = () => navigateToTimeline(user.username);
</script>

<li
  class="item"
  on:click="{switchTimeline}"
  in:fly="{{ delay: index * 100 + 100, x: 300 }}"
  out:slide|local
>
  <span class="label">{user.name}</span>
  <sub>{user.username}</sub>
</li>

<style>
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 25px;
    border-left: var(--timeline-width) solid var(--white);
    padding: var(--spacing-medium);
    transition: var(--default-transition);
  }
  .item .label {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }
  .item:hover {
    border-left: 10px solid var(--white);
  }
  .item sub {
    color: var(--grey-600);
  }
</style>
