<script>
  import UserMenu from "./UserMenu.svelte";
  import Icon from "./common/Icon.svelte";
  import { userLogin } from "../stores/context";
</script>

<header>
  <nav>
    <Icon
      name="home"
      clickable="{true}"
      on:click="{() => (document.location.href = '/')}"
    />
  </nav>

  {#if !!$userLogin}
    <UserMenu />
  {/if}
</header>

<style>
  header {
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    padding: var(--spacing-small);
  }

  nav {
    pointer-events: auto;
    background: var(--color-background);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
  }

  @media only screen and (max-width: 600px) {
    header {
      justify-content: flex-end;
    }
  }
</style>
