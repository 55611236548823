import { user } from "../stores/context";
import fetcher from "./fetcher";

export const parseJwt = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace("-", "+").replace("_", "/");
  return window.atob(base64);
};

export const login = (token, preferences) =>
  user.set({ token, ...JSON.parse(parseJwt(token)), preferences });

export const logout = () => {
  fetcher("/api/private/logout", {
    method: "post",
  }).finally(() => {
    user.set(null);
    localStorage.clear();
    document.location.reload();
  });
};
