<script>
  import { onMount } from "svelte";
  import { Router, Route } from "svelte-routing";
  import Header from "./components/Header.svelte";
  import Toast from "./components/Toast.svelte";
  import Prompt from "./components/Prompt.svelte";
  import Timeline from "./page/Timeline.svelte";
  import LandingPage from "./page/LandingPage.svelte";
  import SettingsPage from "./page/SettingsPage.svelte";
  import { userLogin, user } from "./stores/context";
  import { refreshUser } from "./stores/user";
  import { isConnected } from "./utils/cookie";

  $: darkmode = (($user || {}).preferences || {}).darkmode;

  onMount(() => {
    if (isConnected() && !$user) {
      refreshUser();
    }
  });
</script>

<Router>
  <div class="application" class:dark="{darkmode}">
    <Header />
    {#if !!$userLogin}
      <Route path="/profile" component="{SettingsPage}" />
      <Route path="*path" component="{Timeline}" />
    {:else}
      <Route path="*" component="{LandingPage}" />
    {/if}
  </div>
</Router>
<Toast />
<Prompt />

<style>
  .application {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
