<script>
  import { PAGE_SIZE } from "../../../modules/config";
  import Icon from "../common/Icon.svelte";
  import { currentUser } from "../../stores/context";

  export let event;
  export let transitionForItems;
  export let index;
</script>

<div
  class="forkedEvent"
  on:click="{() => (document.location.href = `/${$currentUser}/${event.url}`)}"
  in:transitionForItems="{{ delay: (index % PAGE_SIZE) * 30 + 400, x: 100 }}"
>
  <div class="name">
    <em>{event.name}</em>
    <Icon name="arrow-right" />
  </div>
  <div
    class="line"
    style="background-image: linear-gradient(to left, transparent 10%, {event.color}
    30%);"
  ></div>
</div>

<style>
  .forkedEvent {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    margin-top: calc(0px - var(--timeline-width));
  }
  .line {
    height: var(--timeline-width);
    margin-left: calc(0px - var(--spacing-large));
  }
  em {
    width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
    overflow: hidden;
    align-self: flex-end;
    font-size: var(--font-size-small);
  }
  .name {
    display: flex;
    justify-content: flex-end;
  }
</style>
