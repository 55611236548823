<script>
  import feather from "feather-icons";
  export let name = "";
  export let attr = { width: 20, height: 20 };
  export let clickable = false;
  export let title = "";
  const svg = feather.icons[name].toSvg({ class: "icon", ...attr });
</script>

<span class:clickable on:click class="iconComponent" title="{title}">
  {@html svg}
</span>

<style>
  :global(.icon) {
    display: inline-block;
    stroke: currentColor;
    fill: none;
  }
  .clickable {
    cursor: pointer;
  }
  span {
    display: flex;
  }
</style>
