<script>
  import { onMount } from "svelte";
  import Icon from "./Icon.svelte";
  export let value = "";
  export let placeholder = "";
  export let disabled = false;
  export let icon = "";
  export let focus = false;

  let input;

  onMount(() => {
    focus && input.focus();
  });
</script>

{#if icon}
  <label>
    <Icon name="{icon}" />
    <input
      type="text"
      bind:value
      bind:this="{input}"
      on:keyup
      on:change
      on:blur
      placeholder="{placeholder}"
      disabled="{disabled}"
      class:hasIcon="{!!icon}"
    />
  </label>
{:else}
  <input
    type="text"
    bind:value
    bind:this="{input}"
    on:keyup
    on:change
    on:blur
    placeholder="{placeholder}"
    disabled="{disabled}"
    class:hasIcon="{!!icon}"
  />
{/if}

<style>
  label {
    display: flex;
    padding-left: var(--spacing-medium);
    align-items: center;
    flex: 1 0 auto;
  }
  label :global(.iconComponent) {
    z-index: 2;
    background: var(--white);
  }
  label input {
    margin: 0;
    margin-left: calc(0px - var(--spacing-x-large) - 1px);
    flex: 1;
  }

  input {
    border: none;
    box-shadow: var(--elevation-0);
    height: var(--input-height);
    font-size: var(--font-size-emphasis);
    padding: var(--spacing-small) var(--spacing-medium);
    margin: var(--spacing-small);
    outline: none;
    text-align: center;
    background-color: var(--white);
    color: var(--grey-900);
  }

  input:disabled {
    background-color: #eee;
  }
</style>
