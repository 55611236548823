<script>
  import { createEventDispatcher } from "svelte";

  export let value = false;

  const dispatch = createEventDispatcher();

  const check = () => {
    value = !value;
    dispatch("toggle");
  };
</script>

<div class="toggle">
  <div class="label" on:click="{check}">
    <slot />
  </div>
  <label class="switch" class:checked="{value}">
    <input type="checkbox" class="checkbox" on:click="{check}" />
  </label>
</div>

<style>
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: var(--grey-400);
    border-radius: 20px;
    transition: all 0.3s;
    cursor: pointer;
    margin: 0 var(--spacing-small);
  }
  .switch::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--white);
    top: 1px;
    left: 1px;
    transition: all 0.3s;
  }

  .switch.checked::after {
    left: 20px;
  }
  .switch.checked {
    background-color: var(--color-brand);
  }
  .checkbox {
    display: none;
  }
  .toggle {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
</style>
