<script>
  import { fly } from "svelte/transition";
  import Signin from "../components/SigninButton.svelte";
  import Footer from "../components/Footer.svelte";
</script>

<div id="betaTag">Beta</div>
<main>
  <div class="landingPage">
    <div class="logoAndSignin" in:fly="{{ y: -200, duration: 1500 }}">
      <img src="/logo.svg" alt="Chronolog.ie" class="logo" />
      <Signin />
    </div>
    <h1 in:fly="{{ y: 200, duration: 1500, delay: 500 }}">
      Log it before you forget it!
    </h1>
  </div>
  <Footer />
</main>

<style>
  .landingPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .logo {
    width: 350px;
  }
  .logoAndSignin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h1 {
    font-weight: 100;
    font-size: var(--font-size-title);
  }
  #betaTag {
    background-color: var(--color-error);
    width: 300px;
    text-align: center;
    position: fixed;
    top: -100px;
    left: -150px;
    transform-origin: right bottom;
    animation: rotateInDownRight 1s linear;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
    padding: var(--spacing-medium);
    font-weight: bold;
    color: var(--white);
  }
  @keyframes rotateInDownRight {
    0% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: rotate3d(0, 0, 1, -50deg);
    }
    75% {
      transform: rotate3d(0, 0, 1, -42deg);
    }
    100% {
      transform: rotate3d(0, 0, 1, -45deg);
      opacity: 1;
    }
  }
</style>
