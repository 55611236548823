import { writable, get } from "svelte/store";

let count = 0;

export const toasts = writable([]);

function createToast(msg, theme, timeout) {
  toasts.set([
    {
      id: count,
      msg,
      theme,
      timeout,
      width: "100%",
    },
    ...get(toasts),
  ]);
  count = count + 1;
}

export const removeToast = (id) => {
  toasts.set(get(toasts).filter((t) => t.id !== id));
};

export const show = (msg, timeout = 3000, theme = "info") => {
  createToast(msg, theme, timeout);
};

export const error = (msg, timeout) => {
  show(msg, timeout, "error");
};

export const warning = (msg, timeout) => {
  show(msg, timeout, "warning");
};

export const info = (msg, timeout) => {
  show(msg, timeout, "info");
};

export const success = (msg, timeout) => {
  show(msg, timeout, "success");
};
