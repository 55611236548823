import fetcher from "../../utils/fetcher";

export const EVENTS = "events";
export const TIMELINES = "timelines";

export const saveEntity = (name = "", entity = {}) =>
  fetcher(`/api/private/${name}${entity._id ? `/${entity._id}` : ""}`, {
    method: entity._id ? "PATCH" : "POST",
    body: JSON.stringify(entity),
  });

export const deleteEntity = (name, id) =>
  fetcher(`/api/private/${name}/${id}`, { method: "DELETE" });

export const loadEntityById = (name, id) =>
  fetcher(`/api/private/${name}/${id}`);
