<script>
  import { toasts, removeToast } from "../stores/toasts";

  const animateOut = (node, { delay = 0, duration = 300 }) => {
    return {
      delay,
      duration,
      css: (t) =>
        `opacity: ${(t - 0.5) * 1}; transform: scaleY(${(t - 0.5) * 1});`,
    };
  };
</script>

<ul class="toasts">
  {#each $toasts as toast (toast.id)}
    <li
      class="toast"
      style="background: var(--color-{toast.theme});"
      out:animateOut
    >
      <div class="content">{toast.msg}</div>
      <div
        class="progress"
        style="animation-duration: {toast.timeout}ms;"
        on:animationend="{() => removeToast(toast.id)}"
      ></div>
    </li>
  {/each}
</ul>

<style>
  .toasts {
    position: fixed;
    top: 0;
    left: calc(50% - 20vw);
    padding: 0;
    margin: 0;
    z-index: 9999;
  }

  .toasts > .toast {
    margin: 10px;
    min-width: 40vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: animate-in 350ms forwards;
    color: #fff;
  }

  .toasts > .toast > .content {
    padding: 10px;
    display: block;
    text-align: center;
    width: 100%;
    font-weight: 500;
    box-shadow: var(--elevation-1);
    box-sizing: border-box;
  }

  .toasts > .toast > .progress {
    position: absolute;
    bottom: 0;
    background-color: rgb(0, 0, 0, 0.3);
    height: 2px;
    width: 100%;
    animation-name: shrink;
    animation-timing-function: linear;
  }

  @keyframes animate-in {
    0% {
      width: 0;
      opacity: 0;
      transform: scale(1.15) translateY(20px);
    }
    100% {
      width: 40vw;
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }

  @keyframes shrink {
    0% {
      width: 40vw;
    }
    100% {
      width: 0;
    }
  }
</style>
