<script>
  import { onMount } from "svelte";
  import { fly, slide, scale } from "svelte/transition";
  import Icon from "./common/Icon.svelte";
  import DefaultEvent from "./events/DefaultEvent.svelte";
  import CreatedEvent from "./events/CreatedEvent.svelte";
  import ForkedEvent from "./events/ForkedEvent.svelte";
  import { PAGE_SIZE } from "../../modules/config";
  import { currentTimeline } from "../stores/timelines";
  import { relativeDate, format } from "../utils/date";
  import {
    TIMELINE_CREATED,
    DEFAULT_EVENT,
    MAIN_TIMELINE_CREATED,
  } from "../utils/consts";

  export let event;
  export let index = 0;

  let component;
  onMount(() => {
    switch (event.type) {
      case MAIN_TIMELINE_CREATED:
        component = CreatedEvent;

        break;
      case TIMELINE_CREATED:
        component = ForkedEvent;
        break;
      default:
        component = DefaultEvent;
        break;
    }
  });

  const eventIcons = {
    [DEFAULT_EVENT]: "activity",
    [TIMELINE_CREATED]: "git-branch",
    [MAIN_TIMELINE_CREATED]: "loader",
  };

  let transitionForItems = window.innerWidth > 600 ? fly : scale;
</script>

<div class="event" out:slide|local>
  <div class="time">
    <span
      class="label"
      in:transitionForItems="{{ delay: (index % PAGE_SIZE) * 30 + 200, x: -100 }}"
      title="{format(event.createdAt)}"
    >
      {relativeDate(event.createdAt)}
    </span>
    <div
      class="iconContainer"
      style="border-color: {event.color || $currentTimeline.color}"
      in:scale
    >
      <Icon name="{eventIcons[event.type]}" />
    </div>
  </div>
  <div class="eventDetails">
    <svelte:component
      this="{component}"
      event="{event}"
      transitionForItems="{transitionForItems}"
      index="{index}"
    />
  </div>
</div>

<style>
  .eventDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: var(--spacing-small);
  }

  .iconContainer {
    width: var(--spacing-x-large);
    height: var(--spacing-x-large);
    border: 3px solid black;
    border-radius: 25px;
    flex-shrink: 0;
    background-color: var(--color-background);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 var(--spacing-medium);
    box-shadow: var(--elevation-0);
  }

  .event {
    display: flex;
    position: relative;
    align-items: flex-start;
    margin: var(--spacing-x-large) 0;
  }
  .event .time {
    flex: 0 0 auto;
    position: sticky;
    top: 20px;
    display: flex;
    align-items: center;
    width: var(--time-width);
    margin-left: calc(0px - var(--time-width) + 14px);
    text-align: right;
    justify-content: flex-end;
    font-size: var(--font-size-small);
    color: var(--grey-700);
  }

  .time .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: var(--time-label-width);
  }
  @media only screen and (max-width: 600px) {
    .event .time .label {
      display: none;
    }
  }
</style>
