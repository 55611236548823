<script>
  import { createEventDispatcher, onMount } from "svelte";

  export let focusOnMount = false;
  export let value = "";
  export let disabled = false;
  const dispatch = createEventDispatcher();
  let element;

  onMount(() => {
    if (focusOnMount) {
      element.focus();
    }
    autoExpand({ target: element });
  });

  const autoExpand = (event) => {
    event.target.style.height = "inherit";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  const onChange = (event) => {
    if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
      event.target.value && dispatch("submit", event.target.value);
    } else if (event.key === "Escape") {
      dispatch("blur");
    }
  };
</script>

<textarea
  bind:value
  on:blur
  on:keydown="{onChange}"
  on:keyup="{autoExpand}"
  on:keyup
  bind:this="{element}"
  disabled="{disabled}"
></textarea>

<style>
  textarea {
    overflow: auto;
    width: 100%;
    min-height: 70px;
    max-height: 50vh;
    outline: none;
    border: none;
    font: inherit;
    resize: none;
    background-color: var(--white);
    color: var(--grey-900);
  }
</style>
