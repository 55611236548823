<script>
  import { slide } from "svelte/transition";
  import Loading from "./Loading.svelte";
  export let secondary = false;
  export let disabled = false;
  export let loading = false;
</script>

<button
  on:click|stopPropagation
  class:secondary
  disabled="{loading || disabled}"
>
  <slot />
  {#if loading}
    <div class="loading" transition:slide>
      <Loading size="{30}" />
    </div>
  {/if}
</button>

<style>
  button {
    min-height: var(--input-height);
    border: none;
    outline: none;
    background: var(--color-success);
    padding: var(--spacing-small) var(--spacing-medium);
    font-weight: bold;
    color: var(--grey-50);
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: var(--font-size-small);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--default-transition);
    position: relative;
  }
  button[disabled] {
    background: var(--grey-400);
    pointer-events: none;
  }
  button:not([disabled]):hover {
    box-shadow: var(--elevation-0);
  }
  button:active {
    box-shadow: none;
  }
  button.secondary {
    font-weight: normal;
    color: var(--color-links);
    background: none;
  }
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
</style>
