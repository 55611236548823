<script>
  import { onMount, tick } from "svelte";
  import { fly } from "svelte/transition";
  import ColorPicker from "./ColorPicker.svelte";
  import Input from "./common/Input.svelte";
  import Button from "./common/Button.svelte";
  import Icon from "./common/Icon.svelte";
  import PrivacyPicker from "./PrivacyPicker.svelte";
  import { BRAND_COLOR } from "../../modules/config";
  import { user } from "../stores/context";
  import { saveEntity, TIMELINES } from "../stores/generic/entity";
  import { urlize } from "../../modules/url";
  import { navigateToTimeline } from "../utils/routing";

  export let timeline = {};

  let name = "";
  let color = "";
  let oldColor;
  let backgroundPreview;
  let oldBackgroundPreview;
  let switching = false;
  let saving = false;
  let privacy = [];
  let isPrivate;

  onMount(async () => {
    await tick(); // TODO remove this and async when https://github.com/sveltejs/svelte/issues/3470 is fixed
    color = timeline.color || BRAND_COLOR;
    oldColor = color;
    name = timeline.name || "";
    privacy = (timeline.privacy || []).slice();
  });

  $: color, switchPreviewColor();

  // Little hack to animate the color transition as linear gradient do not animate
  const switchPreviewColor = () => {
    switching = true;
    oldBackgroundPreview = `background-image: linear-gradient(transparent 10%, ${oldColor} 30%);`;
    backgroundPreview = `background-image: linear-gradient(transparent 10%, ${color} 30%);`;
    setTimeout(() => (switching = false));
  };

  const setOldColor = () => (oldColor = color);
  const submit = async ({ target: { value }, keyCode }) => {
    if (keyCode === 13 && value.trim()) {
      await save();
    }
  };

  const save = async () => {
    if (saving) return;
    saving = true;
    if (!isPrivate) {
      privacy = [];
    }
    const result = await saveEntity(TIMELINES, {
      _id: timeline._id,
      author: $user._id,
      name,
      color,
      privacy,
    });
    navigateToTimeline($user.username, result.url);
  };

  $: url = timeline.isMain
    ? `/${$user.username}`
    : `/${$user.username}/${urlize(name)}`;
  $: title = timeline._id ? "Edit timeline" : "Create timeline";
</script>

<div class="timelineForm">
  <div class="form">
    <div class="formBody">
      <div class="formDetails">
        <div class="pickerContainer">
          <div class="preview" style="{backgroundPreview}"></div>
          <div class="hint">
            <Icon name="corner-left-down" />
            Pick a Color
          </div>
          {#if switching}
            <div
              class="preview old"
              style="{oldBackgroundPreview}"
              out:fly="{{ y: -100 }}"
              on:outroend="{setOldColor}"
            ></div>
          {/if}
          <ColorPicker bind:picked="{color}" />
        </div>
        <div>
          <h3>{title}</h3>
          <Input
            placeholder="Timeline name"
            on:keyup="{submit}"
            bind:value="{name}"
          />
        </div>
      </div>
      <div class="footer">
        <p class="urlPreview" title="{`https://chronologie${url}`}">{url}</p>
        {#if !timeline.isMain}
          <PrivacyPicker bind:privacy bind:isPrivate />
        {/if}
        <Button on:click="{save}" disabled="{!name.trim()}" loading="{saving}">
          {saving ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  </div>
</div>

<style>
  .timelineForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form {
    padding: var(--spacing-large) 0;
    position: relative;
    width: 280px;
  }

  .formDetails {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    align-items: flex-end;
  }
  .preview {
    position: absolute;
    height: 280px;
    left: 24px;
    bottom: 23px;
    width: 8px;
    transform-origin: bottom;
    transform: rotate(15deg);
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .urlPreview {
    font-size: var(--font-size-tiny);
    color: var(--grey-400);
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline dotted;
  }
  .pickerContainer {
    position: relative;
  }

  .pickerContainer .hint {
    transform: rotate(-70deg);
    position: absolute;
    top: -45px;
    left: -30px;
    display: flex;
    white-space: nowrap;
  }
  .pickerContainer .hint :global(.icon) {
    transform: rotate(30deg);
  }
</style>
