import { writable } from "svelte/store";
import logger from "../../../modules/logger";

// we can't actually access localStorage here because
// of iframe restrictions. just roll with it

function read(key) {
  try {
    return JSON.parse(localStorage[key]);
  } catch (e) {
    logger.info(`couldn't use local storage for key: ${key}`);
  }
}

export default function persistent(key, initial) {
  const store = writable(read(key) || initial, () => {
    return store.subscribe((value) => {
      localStorage[key] = JSON.stringify(value);
    });
  });

  return store;
}
