import { MAX_SIZE_IMAGE } from "../../modules/config";
export const compress = (file) => {
  return new Promise((resolve, reject) => {
    const max = MAX_SIZE_IMAGE;
    const fileName = file.name;
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement("canvas");
        if (img.width < MAX_SIZE_IMAGE && img.height < MAX_SIZE_IMAGE) {
          elem.width = img.width;
          elem.height = img.height;
        } else {
          if (img.width > img.height) {
            const scaleFactor = max / img.width;
            elem.width = max;
            elem.height = img.height * scaleFactor;
          } else {
            const scaleFactor = max / img.height;
            elem.height = max;
            elem.width = img.width * scaleFactor;
          }
        }

        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, elem.width, elem.height);
        ctx.canvas.toBlob(
          (blob) => {
            resolve(
              new File([blob], fileName, {
                type: "image/jpeg",
                lastModified: Date.now(),
              })
            );
          },
          "image/jpeg",
          1
        );
      };
      reader.onerror = (error) => reject(error);
    };
    reader.readAsDataURL(file);
  });
};
