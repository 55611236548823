<script>
  import { createEventDispatcher } from "svelte";
  import { fly } from "svelte/transition";
  import Overlay from "./Overlay.svelte";

  export let overflown = false;
  export let large = false;

  const dispatch = createEventDispatcher();
</script>

<Overlay on:click="{() => dispatch('close')}" />

<div class="modal" class:overflown class:large transition:fly="{{ y: 100 }}">
  <slot />
</div>

<style>
  .modal {
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 4em);
    max-width: 400px;
    max-height: calc(100vh - 4em);
    padding: 1em;
    border-radius: 0.2em;
    background: var(--white);
    box-shadow: var(--elevation-2);
    overflow: auto;
  }
  .overflown {
    overflow: visible;
  }
  .large {
    max-width: 90%;
  }
</style>
