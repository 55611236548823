<script>
  import { fly } from "svelte/transition";
  import { prompt } from "../stores/confirm";
  import Button from "./common/Button.svelte";
  import Overlay from "./common/Overlay.svelte";

  const close = () => ($prompt.show = false);
  const confirm = () => {
    setTimeout($prompt.callback);
    close();
  };
</script>

{#if $prompt.show}
  <Overlay on:click="{close}" />
  <div class="prompt" transition:fly="{{ y: 100 }}">
    <div class="content">{$prompt.label}</div>
    <div class="actions">
      <Button on:click="{close}" secondary>Cancel</Button>
      <Button on:click="{confirm}">OK</Button>
    </div>
  </div>
{/if}

<style>
  .prompt {
    position: fixed;
    background: var(--white);
    border-radius: 0.2em;
    z-index: 100;
    left: 50%;
    top: 20vh;
    min-width: 200px;
    max-width: 400px;
    transform: translate(-50%, -50%);
  }
  .content {
    padding: var(--spacing-x-large);
  }
  .actions {
    display: flex;
    justify-content: space-evenly;
    padding: var(--spacing-medium);
  }
</style>
