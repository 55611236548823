<script>
  import Modal from "./common/Modal.svelte";
  import Button from "./common/Button.svelte";
  let showLicense = false;
  let showPrivacyPolicy = false;
</script>

<ul class="footer">
  <li>
    <a
      href="https://forms.gle/AXtsh6eyCDmy2FBL9"
      target="_blank"
      rel="noopener noreferrer"
    >Contact us</a>
  </li>
  <li>
    <Button on:click="{() => (showLicense = true)}" secondary>License</Button>
  </li>
  <li>
    <Button on:click="{() => (showPrivacyPolicy = true)}" secondary>
      Privacy Policy
    </Button>
  </li>
</ul>

{#if showLicense}
  <Modal on:close="{() => (showLicense = false)}" large>
    Copyright 2019 Chronolog.ie
    <p>
      THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
      IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
      FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
      THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
      LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
      FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER
      DEALINGS IN THE SOFTWARE.
    </p>
  </Modal>
{/if}

{#if showPrivacyPolicy}
  <Modal on:close="{() => (showPrivacyPolicy = false)}" large>
    <h1>Privacy Policy</h1>
    <p>
      This privacy policy ("policy") will help you understand how Chronolog.ie
      ("us", "we", "our") uses and protects the data you provide to us when you
      visit and use Chronolog.ie.
    </p>

    <p>
      We reserve the right to change this policy at any given time, of which you
      will be promptly updated. If you want to make sure that you are up to date
      with the latest changes, we advise you to frequently visit this page.
    </p>

    <h2>What User Data We Collect</h2>

    <p>When you log into the website, we collect the following data:</p>

    <ul>
      <li>Your full name and email address.</li>
      <li>Your google avatar url</li>
    </ul>

    <h2>Why We Collect Your Data</h2>

    <p>
      We are collecting your data only for the need of Chronolog.ie features.
    </p>

    <p>
      Chronolog.ie is committed to securing your data and keeping it
      confidential. Chronolog.ie has done all in its power to prevent data
      theft, unauthorized access, and disclosure by implementing the latest
      technologies and software, which help us safeguard all the information we
      collect online.
    </p>

    <h2>Information from Integrated Sign-On Services.</h2>

    <p>
      Chronolog.ie collects your Personal Information that is already associated
      with your Integrated Service account as controlled through your settings
      on that Integrated Service.
    </p>

    <h2>Links to Other Websites</h2>
    Our website contains links that lead to other websites. If you click on
    these links Chronolog.ie is not held responsible for your data and privacy
    protection. Visiting those websites is not governed by this privacy policy
    agreement. Make sure to read the privacy policy documentation of the website
    you go to from our website.
    <h2>About your Personal Data</h2>

    <p>
      Chronolog.ie will not lease, sell or distribute your personal information
      to any third parties. We might do so if the law forces us.
    </p>
  </Modal>
{/if}

<style>
  ul.footer {
    height: var(--footer-height);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ul.footer li {
    margin: 0 var(--spacing-large);
  }
</style>
