<script>
  import { slide } from "svelte/transition";
  import { showPoppover, closeAll } from "../../stores/poppover";
  import { fade } from "svelte/transition";
  import { cubicOut } from "svelte/easing";

  export let name = "";
  export let inTransition = {};
  export let outTransition = {};

  const closePoppover = () => ($showPoppover[name] = false);
  const openPoppover = (name) => {
    closeAll();
    $showPoppover[name] = true;
  };
  let trigger;
  let modal;
  let left;

  const escapeListener = (event) => event.code === "Escape" && closeAll();

  $: opened = $showPoppover[name];

  //If the poppover modal is going to be diplayed too close to right hand side of the window, make it appear on the left
  $: if (
    trigger &&
    modal &&
    trigger.getBoundingClientRect().left > window.innerWidth - modal.offsetWidth
  ) {
    left = -Math.min(
      trigger.getBoundingClientRect().left,
      modal.offsetWidth - trigger.offsetWidth
    );
  } else {
    left = 0;
  }
</script>

<svelte:window on:keydown="{escapeListener}" />

<div class="poppover">
  <button
    bind:this="{trigger}"
    class="trigger"
    on:click|preventDefault="{() => openPoppover(name)}"
    type="button"
  >
    <slot name="trigger" opened="{opened}" />
  </button>
  {#if $showPoppover[name]}
    <div
      class="modal"
      bind:this="{modal}"
      style="left:{left}px"
      in:slide="{{ delay: 0, duration: 400, easing: cubicOut, ...inTransition }}"
      out:slide="{{ delay: 0, duration: 400, easing: cubicOut, ...outTransition }}"
    >
      <slot name="modal" closePoppover="{closePoppover}" />
    </div>
    <div class="overlay" on:click="{closePoppover}" transition:fade></div>
  {/if}
</div>

<style>
  .overlay {
    z-index: 99;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .poppover {
    position: relative;
    width: 100%;
    pointer-events: auto;
  }
  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--grey-900);
    width: 100%;
  }
  .modal {
    position: absolute;
    z-index: 100;
    background-color: var(--white);
    box-shadow: var(--elevation-2);
  }
</style>
