import { derived, writable } from "svelte/store";
import persistent from "./generic/persistent";

export const user = persistent("profile", null);

export const userLogin = derived(user, ($user) => $user && $user.login);

export const currentUser = writable("");

export const isOwnTimeline = derived(
  [user, currentUser],
  ([$user, $currentUser]) => $currentUser === ($user && $user.username)
);
