import { writable } from "svelte/store";

export const prompt = writable({ show: false, label: "", callback: () => {} });

export const confirm = (label = "", callback = () => {}) => {
  prompt.set({
    label,
    callback,
    show: true,
  });
};
