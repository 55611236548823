<script>
  import Editor from "./common/Editor.svelte";
  import Overlay from "./common/Overlay.svelte";
  import MediaPreview from "./common/MediaPreview.svelte";
  import Icon from "./common/Icon.svelte";
  import Button from "./common/Button.svelte";
  import { slide, fly } from "svelte/transition";
  import { EVENTS, saveEntity } from "../stores/generic/entity";
  import { currentTimeline } from "../stores/timelines";
  import { getSignedUrl, upload, deleteMedia } from "../stores/media";
  import { compress } from "../utils/image";

  export let timelineId;

  let focused = false;
  let content = "";
  let sending = false;
  let mediaUrl = "";
  let uploading = false;
  let preview = "";
  let fileInput;
  let fileType = "";

  const handler = () => {
    if (!content.trim()) return;
    sending = true;
    const medias = [];
    if (mediaUrl) medias.push(mediaUrl);
    saveEntity(EVENTS, { timeline: timelineId, content, medias }).then(
      (data) => {
        sending = false;
        $currentTimeline.events = [data, ...$currentTimeline.events];
        content = "";
        clearMedia(true);
      }
    );
    focused = false;
  };

  const clearMedia = (keepUploadedFile) => {
    if (mediaUrl && !keepUploadedFile) deleteMedia(mediaUrl);
    if (fileInput) fileInput.value = "";
    preview = "";
    mediaUrl = "";
  };

  const onFileSelected = async ({ target: { files = [] } }) => {
    if (
      !files[0] ||
      (!files[0].type.startsWith("video/") &&
        !files[0].type.startsWith("image/"))
    )
      return;

    uploading = true;
    fileType = files[0].type.slice(0, 5);

    const file =
      fileType === "image" && files[0].type !== "image/gif"
        ? await compress(files[0])
        : files[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      preview = event.target.result;
      const remoteCall = await getSignedUrl(file);
      mediaUrl = remoteCall.url;
      await upload(file, remoteCall.signedUrl);
      setTimeout(() => (uploading = false), 1500);
    };
    reader.readAsDataURL(file);
  };
</script>

<div class="mainEditor">
  <div
    class="editorTrigger"
    class:sending
    on:click="{() => !sending && (focused = true)}"
  >
    <div class="label">
      {#if content}{content}{:else}Write a new story...{/if}
    </div>
    {#if content}
      <Button on:click="{handler}" loading="{sending}">Post</Button>
    {/if}
  </div>
  {#if focused}
    <div class="editorContainer" in:slide|local out:fly="{{ y: 50 }}">
      <div class="previewContainer" class:preview>
        {#if preview}
          <MediaPreview
            uploading="{uploading}"
            src="{preview}"
            onDelete="{() => clearMedia()}"
            editable="{true}"
            type="{fileType}"
          />
        {/if}
      </div>
      <Editor
        on:submit="{handler}"
        focusOnMount="{true}"
        bind:value="{content}"
        on:keyup="{(e) => e.key === 'Escape' && (focused = false)}"
      />
      <div class="editorFooter">
        <label>
          <Icon
            name="image"
            clickable="{true}"
            attr="{{ height: 24, width: 24 }}"
          />
          <input
            type="file"
            on:change="{onFileSelected}"
            accept="image/*, video/*"
            bind:this="{fileInput}"
          />
        </label>
        <Button on:click="{handler}" disabled="{!content.trim() || uploading}">
          Post
        </Button>
      </div>
    </div>
    <Overlay on:click="{() => (focused = false)}" />
  {/if}
</div>

<style>
  .mainEditor {
    position: relative;
    margin: var(--spacing-x-large) 0 var(--spacing-large) var(--spacing-large);
  }

  .editorTrigger {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
    border: 1px solid transparent;
    font-size: var(--font-size-title);
    padding: var(--spacing-small) var(--spacing-medium);
    transition: var(--default-transition);
    cursor: pointer;
  }

  .editorTrigger .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .editorTrigger:hover {
    box-shadow: var(--elevation-0);
  }
  .previewContainer {
    display: flex;
    overflow: hidden;
    justify-content: center;
    width: 100%;
    max-height: 0;
    transition: var(--default-transition);
  }
  .previewContainer.preview {
    max-height: 400px;
  }

  .editorContainer {
    padding: var(--spacing-medium);
    box-shadow: var(--elevation-2);
    background: var(--white);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .editorFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  input[type="file"] {
    display: none;
  }

  .mainEditor :global(.editorContainer > div) {
    outline: none;
  }
</style>
