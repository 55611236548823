<script>
  import Icon from "./common/Icon.svelte";
</script>

<div class="notFound">
  <div class="icon">
    <Icon name="help-circle" attr="{{ width: 50, height: 50 }}" />
  </div>
  <h3>Timeline not found</h3>
  <p>Go to <a href="/">my timeline</a></p>
</div>

<style>
  .notFound {
    text-align: center;
    padding: var(--spacing-x-large);
    border-radius: var(--spacing-medium);
    background-color: var(--white);
    box-shadow: var(--elevation-0);
  }
  .icon {
    color: var(--grey-600);
  }
</style>
