<script>
  import { onMount } from "svelte";
  import {
    currentTimeline,
    timelines,
    loadTimelinesByUser,
  } from "../stores/timelines";
  import { user } from "../stores/context";
  import { showPoppover } from "../stores/poppover";
  import Icon from "./common/Icon.svelte";
  import Input from "./common/Input.svelte";
  import Loading from "./common/Loading.svelte";
  import Modal from "./common/Modal.svelte";
  import Poppover from "./common/Poppover.svelte";
  import TimelineForm from "./TimelineForm.svelte";
  import TimelineListItem from "./TimelineListItem.svelte";
  import UserListItem from "./UserListItem.svelte";
  import { confirm } from "../stores/confirm";
  import { isOwnTimeline, currentUser } from "../stores/context";
  import { search, searchResults, searching } from "../stores/search";
  import { deleteEntity, TIMELINES } from "../stores/generic/entity";
  import { navigateToTimeline } from "../utils/routing";

  let showTimelineForm = false;
  let timelineListPromise;
  let timelineToEdit;
  let scroll;
  let timelineFilter = "";

  onMount(() => {
    timelineListPromise = loadTimelinesByUser($currentUser);
  });

  const deleteTimeline = (_id, name, closePoppover) => () => {
    closePoppover();
    confirm(`Delete the timeline ${name}?`, () => {
      //don't wait for deletion confirmation (no await)
      deleteEntity(TIMELINES, _id);

      $timelines = $timelines.filter((i) => i._id !== _id);
      if ($currentTimeline._id === _id) {
        navigateToTimeline($user.username);
      }
    });
  };

  const editTimeline = (_id, closePoppover) => () => {
    closePoppover();
    timelineToEdit = $timelines.find((i) => i._id === _id);
    showTimelineForm = true;
  };

  const closeTimelineForm = () => {
    timelineToEdit = undefined;
    showTimelineForm = false;
  };

  const cmdPListener = (event) => {
    if (event.metaKey && event.code === "KeyP") {
      event.preventDefault();
      $showPoppover["timelineSwitcher"] = true;
    }
  };

  const clearSearch = () => {
    setTimeout(() => (timelineFilter = ""), 500);
  };

  $: timelineList = timelineFilter
    ? $timelines.filter((timeline) =>
        timeline.name.toLowerCase().includes(timelineFilter.toLowerCase())
      )
    : $timelines;
  $: timelineFilter, search(timelineFilter);
  $: filteredSearchResults = $searchResults.filter(
    (result) => !$timelines.some((tl) => tl._id === result._id)
  );
</script>

<svelte:window bind:scrollY="{scroll}" on:keydown="{cmdPListener}" />
<div class="titleContainer">
  <div
    class="title"
    style="border-color: {$currentTimeline.color}"
    class:shadow="{scroll}"
  >
    <Poppover name="timelineSwitcher">
      <div slot="trigger" class="trigger" let:opened>
        <span class="label">{$currentTimeline.name}</span>
        <span class="chevron" class:opened>
          <Icon name="chevron-down" />
        </span>
      </div>
      <div slot="modal" let:closePoppover>
        <ul class="timelineList">
          {#if $isOwnTimeline}
            <li
              class="new"
              on:click="{() => (showTimelineForm = true) && closePoppover()}"
            >
              <Icon name="plus" />
              Create New
            </li>
          {/if}
          <li class="separator">
            {$isOwnTimeline ? 'Your timelines' : `${$currentUser}'s timelines`}
          </li>
          {#each timelineList as timeline, index (timeline._id)}
            <TimelineListItem
              timeline="{timeline}"
              index="{index}"
              on:deletetimeline="{deleteTimeline(timeline._id, timeline.name, closePoppover)}"
              on:edittimeline="{editTimeline(timeline._id, closePoppover)}"
            />
          {/each}
          <li class="search">
            <Input
              bind:value="{timelineFilter}"
              placeholder="Search"
              icon="search"
              focus
              on:blur="{clearSearch}"
            />
          </li>
          {#if filteredSearchResults.length}
            <li class="separator">Search results</li>
          {/if}
          {#if $searching}
            <li class="searching">
              <Loading size="{30}" />
            </li>
          {/if}
          {#each filteredSearchResults as searchResult, index (searchResult._id)}
            {#if searchResult.type === 'timeline'}
              <TimelineListItem
                timeline="{searchResult}"
                index="{index}"
                searchResult="{true}"
              />
            {/if}
            {#if searchResult.type === 'user'}
              <UserListItem user="{searchResult}" index="{index}" />
            {/if}
          {/each}
        </ul>
      </div>
    </Poppover>
  </div>
</div>

{#if showTimelineForm}
  <Modal on:close="{closeTimelineForm}" overflown>
    <TimelineForm timeline="{timelineToEdit}" />
  </Modal>
{/if}

<style>
  .titleContainer {
    z-index: 10;
    position: fixed;
    top: 0;
  }

  .titleContainer :global(.title button) {
    font-size: var(--font-size-title);
    padding: 0;
  }
  .titleContainer :global(.poppover > .modal) {
    top: 39px;
  }
  .title {
    margin: 0 var(--spacing-medium);
    width: var(--timeline-max-width);
    background-color: var(--color-background);
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    color: var(--grey-900);
    z-index: 12;
    height: 42px;
    transition: var(--default-transition);
  }

  .title.shadow {
    box-shadow: 0 7px 4px -5px rgba(115, 115, 115, 0.75);
  }
  .trigger {
    display: flex;
    align-items: center;
  }
  .trigger .label {
    padding: var(--spacing-small) var(--spacing-medium);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    cursor: pointer;
  }
  .chevron {
    transition: var(--default-transition);
  }
  .chevron.opened {
    transform: rotate(180deg);
  }
  .timelineList {
    margin: 0;
    padding: 0;
    width: 90vw;
    max-width: var(--timeline-max-width);
  }
  .searching {
    justify-content: center;
  }

  .timelineList li {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-left: var(--timeline-width) solid var(--white);
    padding: var(--spacing-medium);
    transition: var(--default-transition);
  }
  li.new {
    color: var(--color-links);
  }

  .timelineList .search {
    border: 1px solid transparent;
    padding: 0;
  }

  .timelineList li.separator {
    height: 18px;
    font-size: var(--font-size-small);
    justify-content: center;
    padding: var(--spacing-small);
    font-style: italic;
    color: var(--grey-600);
    border-top: 1px solid var(--grey-400);
  }
</style>
