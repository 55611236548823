<script>
  import { fly } from "svelte/transition";
  import Input from "./common/Input.svelte";
  import Poppover from "./common/Poppover.svelte";

  export let picked = "#000000";

  const colors = [
    "#EC407A",
    "#EF5350",
    "#FF7043",
    "#FFA726",
    "#FFCA28",
    "#FFEE58",
    "#26A69A",
    "#8D6E63",
    "#66BB6A",
    "#26C6DA",
    "#BDBDBD",
    "#5C6BC0",
    "#AB47BC",
    "#42A5F5",
    "#7E57C2",
    "#9CCC65",
    "#29B6F6",
    "#D4E157",
    "#FFFFFF",
    "#000000",
  ];

  const transitionDelayPerItem = 10;
</script>

<div class="picker">
  <Poppover
    name="colorPicker"
    outTransition="{{ delay: colors.length * transitionDelayPerItem }}"
  >
    <div slot="trigger">
      <div class="colorPill" style="background-color:{picked}"></div>
    </div>
    <div slot="modal" class="selector" let:closePoppover>
      <ul>
        {#each colors as color, index}
          <li
            class="colorPill"
            in:fly="{{ delay: index * transitionDelayPerItem, y: 200 }}"
            out:fly="{{ delay: (colors.length - index) * transitionDelayPerItem, y: 200 }}"
            style="background-color:{color}"
            on:click="{() => (picked = color) && closePoppover()}"
          ></li>
        {/each}
      </ul>
      <div
        in:fly="{{ delay: colors.length * transitionDelayPerItem, y: 200 }}"
        out:fly="{{ delay: 0, y: 200 }}"
      >
        <Input
          bind:value="{picked}"
          on:keyup="{(event) => event.keyCode === 13 && closePoppover()}"
        />
      </div>
    </div>
  </Poppover>
</div>

<style>
  .picker {
    position: relative;
  }
  .selector {
    text-align: center;
    min-width: 210px;
    padding: var(--spacing-small);
  }
  .selector .colorPill:hover {
    box-shadow: var(--elevation-2);
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  .colorPill {
    width: 2rem;
    height: 2rem;
    margin: var(--spacing-small);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: var(--elevation-1);
    transition: var(--default-transition);
  }
</style>
