<script>
  import { isImage } from "../../utils/mimeUtil";
  import Icon from "./Icon.svelte";
  import LightBox from "./LightBox.svelte";
  import Loading from "./Loading.svelte";
  export let uploading = false;
  export let editable = false;
  export let src = "";
  export let type = isImage(src) ? "image" : "video";
  export let onDelete = () => {};
  const originalSrc = () => src.replace("/m_", "/");
</script>

<div class="preview">
  {#if editable}
    <Icon name="trash" on:click="{onDelete}" clickable="{true}" />
  {/if}
  {#if uploading}
    <div class="loadingMedia">
      <Loading />
    </div>
  {/if}
  {#if type === 'image'}
    <LightBox src="{src}" attr="{{ alt: 'preview' }}" type="{type}">
      <img
        src="{src}"
        alt="preview"
        class:uploading
        onerror="this.src='{originalSrc(src)}'"
      />
    </LightBox>
  {/if}
  {#if type === 'video'}
    <video controls>
      <track kind="captions" />
      <source src="{originalSrc()}" />
      Your browser does not support the video tag.
    </video>
  {/if}
</div>

<style>
  .preview {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--spacing-large);
  }

  .preview img,
  .preview video {
    display: block;
    max-width: 100%;
    max-height: 400px;
    width: auto;
    height: auto;
    margin: 0 auto;
    transition: var(--default-transition);
  }

  img.uploading {
    filter: grayscale(100%);
  }

  .loadingMedia {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .preview :global(.iconComponent > .feather-trash) {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    padding: 0.5rem;
    border-radius: 50%;
    background: var(--white);
  }
</style>
