<script>
  import { onMount } from "svelte";
  import { user } from "../stores/context";
  import Input from "../components/common/Input.svelte";
  import Toggle from "../components/common/Toggle.svelte";
  import Sessions from "../components/Sessions.svelte";
  import Loading from "../components/common/Loading.svelte";
  import { refreshUser, saveUser } from "../stores/user";

  let loadingPromise = refreshUser();
  const handleUsernameChange = async (event) => {
    event.target.blur();
    if (event.target.value && event.target.value.trim()) {
      $user.username = event.target.value.trim();
    }
    saveUser();
    return false;
  };
  let darkmode = false;

  onMount(() => (darkmode = !!$user.preferences.darkmode));
</script>

{#await loadingPromise}
  <Loading />
{:then data}
  <form on:submit|preventDefault>
    <div class="user">
      <div class="login">{data.login}</div>
    </div>
    <span class="label">
      Username:
      <Input value="{data.username}" on:change="{handleUsernameChange}" />
    </span>
    <Toggle bind:value="{$user.preferences.darkmode}" on:toggle="{saveUser}">
      <span class="label">Dark mode</span>
    </Toggle>

    <div class="sessions">
      <Sessions />
    </div>
  </form>
{:catch error}
  <p>Something went wrong: {error.message}</p>
{/await}

<style>
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    box-shadow: var(--elevation-1);
  }
  .user {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--grey-200);
  }

  .sessions {
    width: 100%;
    border-top: 1px solid var(--grey-200);
    margin-top: var(--spacing-x-large);
  }
  .user .login {
    padding: 0 1rem;
  }
  .label {
    margin: var(--spacing-medium) 0;
    color: var(--grey-700);
  }
</style>
