<script>
  import { currentTimeline } from "../../stores/timelines";
  import { PAGE_SIZE } from "../../../modules/config";
  import { absoluteUrl } from "../../stores/media";
  import Editor from "../common/Editor.svelte";
  import Loading from "../common/Loading.svelte";
  import Button from "../common/Button.svelte";
  import { relativeDate, format } from "../../utils/date";
  import { confirm } from "../../stores/confirm";
  import { isOwnTimeline } from "../../stores/context";
  import {
    EVENTS,
    saveEntity,
    deleteEntity,
  } from "../../stores/generic/entity";

  import MediaPreview from "../common/MediaPreview.svelte";

  import Icon from "../common/Icon.svelte";

  export let transitionForItems;
  export let index;
  export let event;
  let editingEvent = false;
  let saving = false;
  let editContent = "";

  const deleteMediaFromEvent = (event, fileIndex) => {
    const newMediasList = event.medias.slice();
    newMediasList.splice(fileIndex, 1);
    return newMediasList;
  };
  const onEditEventClick = () => {
    editingEvent = true;
    editContent = event.content;
  };

  const editHandler = (event) => {
    saving = true;
    event.content = editContent;
    event.updatedAt = new Date();
    saveEntity(EVENTS, event).then(() => {
      editingEvent = null;
      saving = false;
    });
  };

  const onDeleteEventClick = () => {
    confirm("Delete this event?", () => {
      $currentTimeline.events = $currentTimeline.events.filter(
        (i) => i._id !== event._id
      );
      deleteEntity(EVENTS, event._id);
    });
  };
</script>

<div
  class="content"
  in:transitionForItems="{{ delay: (index % PAGE_SIZE) * 30 + 200, x: 100 }}"
>
  {#if $isOwnTimeline}
    <div class="eventMenuContainer">
      <ul class="eventMenu">
        <li>
          <Icon name="edit" on:click="{onEditEventClick}" clickable="{true}" />
        </li>
        <li>
          <Icon
            name="trash"
            on:click="{onDeleteEventClick}"
            clickable="{true}"
          />
        </li>
      </ul>
    </div>
  {/if}
  {#if event.medias.length > 0}
    <div class="medias">
      {#each event.medias as media, i (media)}
        <MediaPreview
          src="{absoluteUrl(`m_${media}`)}"
          editable="{editingEvent}"
          onDelete="{() => (event.medias = deleteMediaFromEvent(event, i))}"
        />
      {/each}
    </div>
  {/if}
  {#if editingEvent}
    <Editor
      bind:value="{editContent}"
      focusOnMount="{true}"
      on:submit="{() => editHandler(event)}"
      disabled="{saving}"
    />
    {#if saving}
      <Loading size="{32}" />
    {:else}
      <ul class="actions">
        <li>
          <Button on:click="{() => (editingEvent = null)}" secondary="{true}">
            Cancel
          </Button>
        </li>
        <li>
          <Button on:click="{() => editHandler(event)}">Save</Button>
        </li>
      </ul>
    {/if}
  {:else}{event.content}{/if}
</div>
<div class="footer">
  <div class="createdAt hideOnMediumAndUp" title="{format(event.createdAt)}">
    {relativeDate(event.createdAt)}
  </div>
  {#if event.createdAt !== event.updatedAt}
    <div class="updatedAt" title="{format(event.updatedAt)}">
      <span class="hideOnMediumAndUp">&nbsp;-</span>
      Edited:
      {format(event.updatedAt)}
    </div>
  {/if}
</div>

<style>
  .content {
    white-space: pre-line;
    box-sizing: border-box;
    padding: var(--spacing-medium);
    position: relative;
    border: 1px solid var(--grey-300);
    background: var(--white);
  }

  .content:after,
  .content:before {
    right: 100%;
    top: 17px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .content:after {
    border-color: transparent;
    border-right-color: var(--white);
    border-width: 10px;
    margin-top: -10px;
  }
  .content:before {
    border-color: transparent;
    border-right-color: var(--grey-300);
    border-width: 11px;
    margin-top: -11px;
  }
  .eventMenuContainer {
    position: absolute;
    overflow: auto;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    justify-content: flex-end;
    z-index: 1;
  }
  .eventMenu {
    margin: 0;
    padding: 0;
    display: none;
    opacity: 0.7;
    justify-content: flex-end;
    background: var(--grey-200);
    border-bottom-left-radius: var(--spacing-small);
  }
  .actions {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .actions li {
    margin: 0 var(--spacing-medium) 0 0;
  }
  .eventMenu li {
    margin: var(--spacing-small);
    filter: drop-shadow(3px 3px 2px rgba(32, 32, 32, 0.5));
  }

  .eventMenu li:hover {
    filter: none;
    color: var(--color-links);
  }
  .createdAt,
  .updatedAt {
    font-size: var(--font-size-tiny);
    color: var(--grey-700);
  }
  .updatedAt {
    color: var(--grey-500);
  }
  .footer {
    display: flex;
  }
  .content:hover .eventMenuContainer .eventMenu {
    display: flex;
  }

  @media only screen and (min-width: 601px) {
    .createdAt {
      display: none;
    }
  }
</style>
