<script>
  import fetcher from "../utils/fetcher";
  import Icon from "./common/Icon";
  import Button from "./common/Button";
  import Input from "./common/Input";
  import { onMount } from "svelte";
  import { isConnected, clearCookies } from "../utils/cookie";
  import { success, error } from "../stores/toasts";

  let value;
  let connected = false;
  let status = "idle";

  onMount(() => {
    connected = isConnected();
  });

  const submit = async () => {
    if (connected) {
      await fetcher("/api/private/logout", {
        method: "POST",
      });
      clearCookies();
      document.location.reload();
    } else if (value.trim()) {
      status = "sending";
      fetcher("/api/auth", {
        method: "POST",
        body: JSON.stringify({ email: value }),
      })
        .then(() => {
          status = "sent";
          success("A key has been sent. Please check your inbox.");
        })
        .catch((e) => {
          status = "error";
          error(e);
        });
    }
    return false;
  };
</script>

<form on:submit|preventDefault="{submit}">
  <div class="inputField">
    {#if !connected}
      <Input
        disabled="{status !== 'idle'}"
        type="email"
        placeholder="Email"
        bind:value
      />
      <div
        class="hint"
        class:sending="{status === 'sending'}"
        class:sent="{status === 'sent'}"
        class:error="{status === 'error'}"
      >
        {#if status === 'sending'}
          <Icon name="loader" />
        {:else if status === 'sent'}
          <Icon name="check" />
        {:else if status === 'error'}
          <Icon name="alert-triangle" />
        {/if}
      </div>
    {:else}
      <Button type="submit">
        Logout
        <Icon name="log-out" />
      </Button>
    {/if}
  </div>
</form>

<style>
  .hint {
    transition-property: opacity, background;
    transition-timing-function: ease-out;
    transition-duration: 300ms;
    width: 30px;
    height: 30px;
    opacity: 0;
    position: absolute;
    right: var(--spacing-medium);
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: var(--elevation-0);
    color: var(--white);
  }
  .inputField {
    position: relative;
  }
  .sending,
  .sent,
  .error {
    opacity: 1;
  }

  .sending {
    background: var(--color-warning);
  }

  .error {
    background: var(--color-error);
  }
  .sent {
    background: var(--color-success);
  }
  :global(.sending .iconComponent) {
    animation: spin 4s linear infinite;
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
