import { writable } from "svelte/store";
import fetcher from "../utils/fetcher";
import { debounce } from "../utils/debounce";

export const searchResults = writable([]);
export const searching = writable(false);

const searchInternal = (name) => {
  if (!name) {
    searchResults.set([]);
  } else {
    searching.set(true);
    fetcher(`/api/private/search?name=${name}`)
      .then((data) => {
        searching.set(false);
        searchResults.set(data);
      })
      .catch(() => {
        searching.set(false);
        searchResults.set([]);
      });
  }
};
let interval;

export const search = debounce(searchInternal, 500, interval);
