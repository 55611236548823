<script>
  import Overlay from "./Overlay.svelte";
  import Icon from "./Icon.svelte";
  export let attr = {};
  export let src = "";
  export let type = "image";
  let origin;
  let opened;

  function zoom(node, { delay = 0, duration = 200 }) {
    const {
      x: origX,
      y: origY,
      width: origWidth,
      height: origHeight,
    } = origin.getBoundingClientRect();
    const {
      x: destX,
      y: destY,
      width: destWidth,
      height: destHeight,
    } = node.getBoundingClientRect();
    return {
      delay,
      duration,
      css: (t) => `left: ${(1 - t) * origX + t * destX}px;
                top: ${(1 - t) * origY + t * destY}px;
                width: ${(1 - t) * origWidth + t * destWidth}px;
                height: ${(1 - t) * origHeight + t * destHeight}px`,
    };
  }

  $: fullResolution = src.replace("/m_", "/");
  const closeOverlay = () => (opened = false);
</script>

<div on:click="{() => (opened = true)}" bind:this="{origin}">
  {#if fullResolution.endsWith('.gif')}
    <div class="playOverlay">
      <Icon name="play" attr="{{ width: 50, height: 50 }}" />
    </div>
  {/if}
  <slot />
</div>

{#if opened}
  <div class="opened" transition:zoom on:click|stopPropagation="{closeOverlay}">
    {#if type === 'image'}
      <img
        src="{fullResolution}"
        {...attr}
        alt="{attr.alt}"
        on:click|stopPropagation="{() => {}}"
      />
    {/if}
    {#if type === 'video'}
      <video width="320" height="420" controls>
        <track kind="captions" />
        <source src="{src}" />
        Your browser does not support the video tag.
      </video>
    {/if}
  </div>
  <Overlay on:click="{closeOverlay}" />
{/if}

<style>
  .opened {
    z-index: 100;
    position: fixed;
    top: var(--spacing-xx-large);
    bottom: var(--spacing-xx-large);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .playOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-900);
    cursor: pointer;
  }
  .playOverlay :global(.iconComponent) {
    background: #888a;
    border-radius: 50%;
    padding: 1rem;
  }
  .opened img {
    box-shadow: var(--elevation-3);
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
</style>
